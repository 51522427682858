<template>
  <div class="login-wrap">
    <div class="header-wrap"></div>
    <div class="login-item-wrap">
      <div class="form-wrap">
        <div class="form-item">
          <MyField :required="true" v-model="state.name" maxlength="11" label="姓名" placeholder="请输入姓名" />
        </div>
        <div class="form-item">
          <MyField @on-focus="onFocus" @on-blur="onBlur" :required="true" v-model="state.company.company_name" label="工作单位" placeholder="请输入工作单位" />
          <div class="field-list" :class="companyShow?'show':'hide'">
            <template v-for="item in state.fieldList" :key='item'>
              <div @click="fieldClick(item)" class="field-item van-ellipsis">{{ item.company_name }}</div>
            </template>
          </div>
        </div>
        <template v-if="state.showType === 1">
          <div class="form-item">
            <MyField
              v-model="state.department"
              label="部门"
              title="请选择部门"
              placeholder="请输入部门"
              :readonly="true"
              :required="true"
              :columns="department"
            />
          </div>
          <div class="form-item">
            <MyField
              v-model="state.position"
              label="职位"
              title="请选择职位"
              placeholder="请选择职位"
              :readonly="true"
              :required="true"
              :columns="part"
            />
          </div>
        </template>
        <template v-if="state.showType === 2">
          <div class="form-item">
            <MyField
              v-model="state.department"
              label="科室"
              placeholder="请选择科室"
              title="请选择科室"
              :readonly="true"
              :required="true"
              :columns="office"
            />
          </div>
          <div class="form-item">
            <MyField
              v-model="state.position"
              label="职称"
              placeholder="请选择职称"
              title="请选择职称"
              :readonly="true"
              :required="true"
              :columns="position"
            />
          </div>
        </template>
        <div class="form-item">
          <MyField :required="true" v-model="state.mobile" maxlength="11" label="手机号码" placeholder="请输入手机号码" />
        </div>
        <div class="form-item">
          <MyField :required="true" v-model="state.sms" maxlength="6" label="验证码" placeholder="请输入验证码">
            <div>
              <div class="vertifiy-wrap" v-if="!isCountDown" @click="vertifyHandle">
                <span class="line">|</span>
                <span>获取验证码</span>
              </div>
              <div class="my-countdown" v-else>
                <span class="line">|</span>
                <CountDown @finish="finishHandle" :time="time" format="ss" />
                <div class="secends">s</div>
              </div>
            </div>
          </MyField>
        </div>
        <div class="form-item">
          <MyField :type="'password'" :required="true" v-model="state.mm" maxlength="16" label="密码" placeholder="请输入密码" />
        </div>
        <div class="form-item">
          <MyField :type="'password'" :required="true" v-model="state.confirm_mm" maxlength="16" label="确认密码" placeholder="请再次输入密码" />
        </div>
        <div class="form-item">
          <MyField v-model="state.email" label="邮箱" placeholder="请输入邮箱" />
        </div>
        <div class="auto-login">
          <Checkbox name="checkBox" v-model="checked" icon-size="16px" shape="square">
            <span class="text">我已阅读并同意<span class="text-click" @click="state.isShow = true">《XX医药平台规范声明》</span></span>
          </Checkbox>
        </div>
        <div class="login-btn" @click="confirmHandle">注册</div>
      </div>
    </div>
  </div>
  <Popup v-model:show="state.isShow">
    展示的内容
  </Popup>
</template>

<script>
import { reactive, ref, watch } from 'vue'
import { Toast, Checkbox, Popup, CountDown } from 'vant'
import MyField from '@/components/my-input/'
import { getCaptcha, getInstitute, registerApi } from '@/api/'
import { office, position, department, part } from './data/'
import { useRouter } from 'vue-router'
import { deleteObjEmptyData } from '@/utils/'

export default {
  name: 'completeInfo',
  components: {
    MyField,
    Checkbox,
    Popup,
    CountDown
  },
  setup () {
    const state = reactive({
      timer: null,
      mobile: '',
      sms: '',
      showType: 0,
      name: '',
      company: {
        company_name: '',
        company_type: 3
      },
      mm: '',
      confirm_mm: '',
      department: '',
      position: '',
      email: '',
      isShow: false,
      dropDown: false,
      fieldList: []
    })

    const regMobile =
      /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
    const regemail =
      /^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/
    const regPassword = /^[0-9a-zA-Z_@#$%^.&]{6,16}$/

    const checked = ref(false)
    const isCountDown = ref(false)
    const time = ref(60 * 1000)
    const router = useRouter()

    const finishHandle = () => {
      isCountDown.value = false
    }
    const companyShow = ref(false)

    const depatrmentShow = ref(false)

    const positionShow = ref(false)

    watch(
      () => state.company.company_name,
      (newVal, oldVal) => {
        clearTimeout(state.timer)
        if (newVal !== '') {
          state.timer = setTimeout(() => {
            getInstitute({
              keyword: state.company.company_name
            }).then((res) => {
              if (res.code === 200) {
                state.fieldList = res.data
              }
            })
          }, 500)
        } else {
          state.showType = 0
          state.fieldList = []
        }
      }
    )
    const vertifyHandle = () => {
      if (regMobile.test(state.mobile)) {
        isCountDown.value = true
        getCaptcha({
          mobile: state.mobile
        }).then((res) => {
          console.log(res)
        })
      } else {
        Toast('请输入正确的手机号码')
      }
    }

    const onFocus = () => {
      // state.fieldList.length>0&&state.fieldList[0].company_name!=state.company.company_name
      companyShow.value = true
    }
    const checkCompany = () => {
      let flag = false
      for (let index = 0; index < state.fieldList.length; index++) {
        const el = state.fieldList[index]
        if (el.company_name === state.company.company_name) {
          flag = true
          break
        }
      }
      return flag
    }

    const onBlur = () => {
      if (!checkCompany()) {
        state.showType = 0
        state.company = {
          company_type: 3,
          company_name: state.company.company_name
        }
      }
      companyShow.value = false
    }

    const confirmHandle = () => {
      const reg = /^[ ]+$/g
      if (!checked.value) {
        Toast('请阅读并勾选声明协议')
      } else if (reg.test(state.name) || state.name === '') {
        Toast('姓名不能为空')
      } else if (state.company.company_name === '') {
        Toast('工作单位不能为空')
      } else if (state.showType === 1 && state.department === '') {
        Toast('部门不能为空')
      } else if (state.showType === 1 && state.position === '') {
        Toast('职位不能为空')
      } else if (state.showType === 2 && state.department === '') {
        Toast('科室不能为空')
      } else if (state.showType === 2 && state.position === '') {
        Toast('职称不能为空')
      } else if (!regMobile.test(state.mobile)) {
        Toast('请输入正确的手机号码')
      } else if (state.sms === '') {
        Toast('请输入验证码')
      } else if (state.sms.length !== 6) {
        Toast('请输入正确的6位验证码')
      } else if (state.mm === '' || state.confirm_mm === '') {
        Toast('密码不能为空')
      } else if (state.mm !== state.confirm_mm) {
        Toast('两次密码输入不一致')
      } else if (!regPassword.test(state.mm) || !regPassword.test(state.confirm_mm)) {
        Toast('密码应为6～16位字母、数字或符号')
      } else if (state.email !== '' && !regemail.test(state.email)) {
        Toast('请输入正确格式的邮箱')
      } else {
        // 确定
        let register = {
          ...state.company,
          password: state.mm,
          confirm_password: state.confirm_mm,
          department: state.department,
          email: state.email,
          mobile: state.mobile,
          name: state.name,
          position: state.position,
          sms: state.sms
        }
        register = deleteObjEmptyData(register)
        registerApi(register).then(res => {
          if (res.code === 200) {
            window.localStorage.setItem('token', res.data.token)
            window.localStorage.setItem('userId', res.data.id)
            router.replace('/home')
          } else {
            Toast(res.message)
          }
        })
      }
    }
    const fieldClick = (item) => {
      companyShow.value = false
      state.company = { ...item }
      if (state.company.company_name.indexOf('公司') !== -1) {
        state.showType = 1
        state.department = ''
        state.position = ''
      } else if (state.company.company_name.indexOf('医院') !== -1) {
        state.showType = 2
        state.department = ''
        state.position = ''
      }
    }
    return {
      depatrmentShow,
      positionShow,
      state,
      checked,
      confirmHandle,
      onFocus,
      onBlur,
      fieldClick,
      companyShow,
      isCountDown,
      vertifyHandle,
      time,
      finishHandle,
      office,
      position,
      department,
      part
    }
  }
}
</script>

<style lang="less" scoped>
.login-wrap {
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: auto;
}
.header-wrap {
  width: 100%;
  height: 162px;
  background: url("../../assets/login-bg.png") no-repeat;
  background-size: cover;
}
.login-item-wrap {
  margin-top: 56px;
  .tab-wrap {
    display: flex;
    padding: 12px 36px 0 36px;
    .tab-item {
      position: relative;
      font-size: 14px;
      color: #666666;
      line-height: 20px;
      &:nth-of-type(1) {
        margin-right: 24px;
      }
    }
    .tab-item-active {
      color: #0080ff;
      &::after {
        content: "";
        position: absolute;
        box-sizing: border-box;
        width: 22px;
        height: 2px;
        background: #0080ff;
        border-radius: 1px;
        pointer-events: none;
        left: 0;
        right: 0;
        bottom: -2px;
        margin: auto;
      }
    }
  }
  .form-wrap {
    padding: 0 36px;
    .form-item {
      position: relative;
      margin-top: 36px;
      .field-list {
        position: absolute;
        transition: all 0.3s ease-in-out;
        margin-top: 4px;
        overflow: auto;
        // width: 100%;
        box-shadow: 0px 0px 4px 0px rgba(187, 187, 187, 0.5);
        border-radius: 4px;
        background-color: #fff;
        z-index: 1000;
        .field-item {
          padding: 0px 16px;
          font-size: 14px;
          color: #333333;
          background-color: #fff;
          line-height: 32px;
        }
      }
      .show {
        padding-top: 8px;
        max-height: 116px;
      }
      .hide {
        max-height: 0px;
      }
    }
  }
  .auto-login {
    display: flex;
    margin-top: 16px;
    .text {
      font-size: 12px;
      color: #494e53;
    }
    .text-click {
      font-size: 12px;
      color: #007fff;
      cursor: pointer;
    }
  }
}
.login-btn {
  width: 100%;
  margin: 52px 0 55px 0;
  height: 44px;
  background: #0c86fe;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
}
.vertifiy-wrap {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #007fff;
  white-space: nowrap;
  .line {
    color: #b9c6d3;
    margin-right: 8px;
  }
}
.my-countdown {
  width: 81px;
  display: flex;
  justify-content: center;
  .line {
    color: #b9c6d3;
    margin-right: 8px;
    font-size: 14px;
  }
  .secends {
    color: #b9c6d3;
    margin-left: 4px;
    font-size: 14px;
  }
}
</style>
